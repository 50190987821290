import { PaymentConfig } from '@frontend/client/contexts/useYodlStore';
import {
  isUSDStableSymbol,
  SUPPORTED_CHAINS,
  SupportedChainId,
} from '@frontend/common';
import { resolveChainIdOrShortName } from '@frontend/utils/chains';
import { getTokenBySymbol, TokenInfo } from '@yodlpay/tokenlists';
import _ from 'lodash';
import { ReadonlyURLSearchParams } from 'next/navigation';

export const chainsParamToSymbols = (chains?: string | string[] | null) => {
  if (!chains) return undefined;

  const supportedChainIds = SUPPORTED_CHAINS.map((c) => c.id);
  const chainArray = Array.isArray(chains) ? chains : chains.split(',');

  return chainArray
    .map(resolveChainIdOrShortName)
    .filter(
      (value) => value && supportedChainIds.includes(value as SupportedChainId),
    ) as SupportedChainId[];
};

export const chainSymbolsToParam = (chainIds?: number[] | null) => {
  if (chainIds) {
    return chainIds.join(',');
  } else {
    return undefined;
  }
};

export const tokenParamToSymbols = (tokens?: string | string[] | null) => {
  if (tokens) {
    const tokenSymbols = Array.isArray(tokens)
      ? tokens.filter((s) => s.length > 1)
      : tokens.split(',').filter((s) => s.length > 1);

    return tokenSymbols.map((s) => s.toUpperCase()).filter(isUSDStableSymbol);
  } else {
    return undefined;
  }
};

export const tokenSymbolsToParam = (tokenSymbols?: string[] | null) => {
  if (tokenSymbols) {
    return tokenSymbols.join(',');
  } else {
    return undefined;
  }
};

/*
 * Parse urlTokenIn query param (used in testing only)
 * Format: shortName:symbol
 * E.g. urlTokenIn=arb1:USDC
 *
 * This is in line with EIP3770:
 * https://eips.ethereum.org/EIPS/eip-3770
 */
const parseUrlTokenIn = (urlTokenIn?: string | null): TokenInfo | undefined => {
  if (!urlTokenIn) return;

  const [shortName, symbol] = urlTokenIn.split(':');
  const chainId = resolveChainIdOrShortName(shortName);
  if (!chainId) return;

  return getTokenBySymbol(symbol, chainId) || undefined;
};

export function parseSearchParams(searchParams: ReadonlyURLSearchParams) {
  const amount = searchParams.get('amount') || undefined;
  let currency = searchParams.get('currency') || undefined;
  const memo = searchParams.get('memo') || undefined;
  const theme = searchParams.get('accent') || undefined;
  const tokens = searchParams.get('tokens') || undefined;
  const chains = searchParams.get('chains') || undefined;
  const urlTokenIn = searchParams.get('urlTokenIn') || undefined;

  // If amount is given, default to USD
  // if (amount && !currency) {
  //   currency = 'USD';
  // }

  const paymentConfig: PaymentConfig = {
    amount: amount ? Number(amount) : undefined,
    currency: currency && currency.toUpperCase().split(',')[0],
    memo,
    theme,
    tokenSymbols: _.compact(tokenParamToSymbols(tokens)),
    chainIds: chainsParamToSymbols(chains),
    urlTokenIn: parseUrlTokenIn(urlTokenIn),
  };

  return paymentConfig;
}
