import { DOMAIN, ORIGIN, YODL_ENS_DOMAIN } from '@frontend/common';
import { JustaName } from '@justaname.id/sdk';
import { mainnet, sepolia } from 'viem/chains';

const providerUrl =
  process.env.NEXT_PUBLIC_MAINNET_PROVIDER_URL ||
  mainnet.rpcUrls.default.http[0];

// Initialize the SDK with your configuration
const justanameClient = JustaName.init({
  networks: [
    {
      chainId: 1, // Ethereum Mainnet
      providerUrl,
    },
    {
      chainId: sepolia.id,
      providerUrl: 'https://sepolia.drpc.org',
    },
  ],
  config: {
    domain: DOMAIN,
    origin: ORIGIN,
  },
  ensDomains: [
    {
      ensDomain: YODL_ENS_DOMAIN,
      apiKey: process.env.NEXT_PUBLIC_MAINNET_API_KEY,
      chainId: mainnet.id,
    },
  ],
});

export { justanameClient };
