import { DEFAULT_FIAT_CURRENCY_DECIMALS } from '@frontend/common/constants/currency';
import type { TokenInfo } from '@yodlpay/tokenlists';
import { getChain, getTokens } from '@yodlpay/tokenlists';
import {
  differenceInDays,
  format,
  formatDistanceToNow,
  isToday,
  isYesterday,
} from 'date-fns';
import * as dn from 'dnum';
import { type Chain } from 'viem';
import * as chains from 'viem/chains';
import {
  CURRENCY_TO_SYMBOL,
  FIAT_PRECISION_DECIMALS,
  MAX_RAW_BALANCE,
  MIN_RAW_BALANCE,
  NATIVE_TOKEN_ADDRESS,
  TOKEN_DECIMALS,
} from '../constants';
import { Decimal } from '../lib';
import type { FormatAmountParams, Link } from '../types';

export const MaxUint256 = BigInt(2 ** 256) - BigInt(1);

export function validateEmail(email: unknown): email is string {
  return typeof email === 'string' && email.length > 3 && email.includes('@');
}

// TODO:
// 0.003 => 0.0030
export function dnformat({
  value,
  decimals,
}: {
  value: bigint;
  decimals: number;
}) {
  if (!value) return undefined;
  const dustDigits = 2;

  const v = dn.from(value, 6); // 6 is precision and does not have effect on display
  const dnum = dn.divide(v, 10 ** decimals);

  const sigZeros = Math.max(dnum[1] - dnum[0].toString().length, 0);

  return dn.format(dnum, {
    digits: sigZeros + dustDigits,
    trailingZeros: false,
  });
}

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const isValidNumber = (value?: string | null | number): boolean => {
  if (!value) return false;
  const num = Number(value);

  return !isNaN(num) && value.toString().trim() !== '';
};

export const parseUnitsDecimal = (value: string | number, decimals: number) => {
  return BigInt(
    new Decimal(value)
      .mul(new Decimal((10n ** BigInt(decimals)).toString()))
      .toFixed(0),
  );
};

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * @param address Full address to truncate
 * @param size How many digits to keep
 * @returns Truncated address
 */
export const truncateAddress = (address: string, size: number) => {
  // kinda stupid but works
  const truncateRegex = {
    1: /^(0x[a-zA-Z0-9]{1})[a-zA-Z0-9]+([a-zA-Z0-9]{1})$/,
    2: /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/,
    3: /^(0x[a-zA-Z0-9]{3})[a-zA-Z0-9]+([a-zA-Z0-9]{3})$/,
    4: /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/,
  };
  const match = address.match(truncateRegex[size]);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const truncateTxHash = (
  hash: string,
  start: number = 8,
  end: number = 0,
): string => {
  if (hash.length <= 2 * start) {
    return hash;
  }

  if (end == 0) {
    return `${hash.slice(0, start)}...`;
  } else {
    return `${hash.slice(0, start)}...${hash.slice(-end)}`;
  }
};

export const formatRelativeTimestamp = (
  timestamp: Date | string,
  threshold = 7,
) => {
  const date = new Date(timestamp);
  const now = new Date();

  if (isToday(date)) {
    // Format as "Today, H:mm pm"
    return `Today, ${format(date, 'p')}`;
  } else if (isYesterday(date)) {
    // Format as "Yesterday, H:mm pm"
    return `Yesterday, ${format(date, 'p')}`;
  } else if (differenceInDays(now, date) < threshold) {
    // Format as "X days ago, H:mm pm"
    const daysAgo = formatDistanceToNow(date, { addSuffix: false });
    return `${daysAgo}, ${format(date, 'p')}`;
  } else {
    // Format as "01.01.2023 H:mm pm" for dates older than the specified threshold (7 days default)
    return format(date, 'dd.MM.yy p');
  }
};

export const formatExactTimestamp = (timestamp: Date | string) => {
  return format(new Date(new Date(timestamp)), 'yyyy-MM-dd HH:mm');
};

export const getPreviewUrl = (link: Link, parentLink?: Link | null) => {
  return `/${link.handle}`;
};

export const getLinkHandle = (link: Link, parentLink?: Link | null) => {
  return parentLink ? `${parentLink.handle}/${link.handle}` : `${link.handle}`;
};

export const getViemChain = (id: number): Chain | undefined => {
  return Object.values(chains).find((x) => x.id === id);
};

// In our tokenlist we will always represent the native token with the native token address
export const isNativeToken = (tokenInfo?: TokenInfo) =>
  tokenInfo?.address?.toLowerCase() === NATIVE_TOKEN_ADDRESS.toLowerCase();

export const coinIdToToken = (coinId: string) => {
  const [symbol, chainIdString] = coinId.split('-');
  const token = getTokens(parseInt(chainIdString)).find(
    (t) => t.symbol === symbol,
  );
  return token;
};

export const getFractionDigits = (amount: number) => {
  const decimals = amount.toString().split('.')[1];
  return decimals ? decimals.replace(/0+$/, '').length : 0;
};

export const formatCurrencySymbol = (
  amount: string,
  symbol = '',
  prefix = '',
  isFiatOrStablecoin = true,
) => {
  if (isFiatOrStablecoin) {
    return `${prefix}${symbol}${amount}`;
  }
  return `${prefix}${amount}${symbol ? ` ${symbol}` : ''}`;
};

export const formatBalanceAmount = ({
  amount,
  currency = '',
  symbol = currency,
  isFiatOrStablecoin = true,
  converted = false,
  shouldFormatCurrency = true,
}: FormatAmountParams) => {
  const currencySymbol =
    CURRENCY_TO_SYMBOL[currency as keyof typeof CURRENCY_TO_SYMBOL]?.symbol ||
    symbol ||
    '';

  const flooredAmount =
    Math.floor(amount * (isFiatOrStablecoin ? 100 : 10)) /
    (isFiatOrStablecoin ? 100 : 10);

  const fractionDigits = isFiatOrStablecoin
    ? 2
    : getFractionDigits(flooredAmount);

  const formatter = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  if (flooredAmount < MIN_RAW_BALANCE) {
    return shouldFormatCurrency
      ? formatCurrencySymbol(`0.01`, currencySymbol, '<', isFiatOrStablecoin)
      : '0.01';
  }

  if (flooredAmount >= MAX_RAW_BALANCE) {
    const compactFormatter = new Intl.NumberFormat('en-GB', {
      notation: 'compact',
      compactDisplay: 'short',
    });
    return shouldFormatCurrency
      ? formatCurrencySymbol(
          compactFormatter.format(flooredAmount),
          currencySymbol,
          '~',
          isFiatOrStablecoin,
        )
      : compactFormatter.format(flooredAmount);
  }

  return shouldFormatCurrency
    ? formatCurrencySymbol(
        formatter.format(flooredAmount),
        currencySymbol,
        converted ? '~' : '',
        isFiatOrStablecoin,
      )
    : formatter.format(flooredAmount);
};

export const formatBalance = (
  balance: number,
  decimals: number,
  symbol: string = '',
) => {
  if (balance) {
    const divisor = 10 ** decimals;
    const balanceAmount = balance;

    const balanceHuman = balanceAmount / divisor;
    const balanceSanitized = Math.abs(balanceHuman);

    return formatBalanceAmount({
      amount: balanceSanitized,
      isFiatOrStablecoin: false,
      ...(symbol && { symbol }),
    });
  }
  return formatBalanceAmount({ amount: 0, isFiatOrStablecoin: false });
};

export const formatPaymentAmount = ({
  amount,
  currency = '',
  symbol = currency,
  isFiatOrStablecoin = true,
  shouldFormatCurrency = true,
  useHigherPrecisionForSmallAmounts = false,
  precision = isFiatOrStablecoin
    ? DEFAULT_FIAT_CURRENCY_DECIMALS
    : TOKEN_DECIMALS,
}: FormatAmountParams): string => {
  const currencySymbol =
    CURRENCY_TO_SYMBOL[currency as keyof typeof CURRENCY_TO_SYMBOL]?.symbol ||
    symbol ||
    '';

  const smallAmountThreshold = Math.pow(10, -DEFAULT_FIAT_CURRENCY_DECIMALS);

  const shouldUseHigherPrecision =
    amount < smallAmountThreshold &&
    useHigherPrecisionForSmallAmounts &&
    isFiatOrStablecoin;

  const finalPrecision = shouldUseHigherPrecision
    ? FIAT_PRECISION_DECIMALS
    : precision;

  const scaleFactor = Math.pow(10, finalPrecision);
  const roundedAmount = Math.round(amount * scaleFactor) / scaleFactor;

  const fractionDigits = isFiatOrStablecoin
    ? shouldUseHigherPrecision
      ? Math.min(getFractionDigits(roundedAmount), FIAT_PRECISION_DECIMALS)
      : finalPrecision
    : Math.min(getFractionDigits(roundedAmount), finalPrecision);

  const formatter = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits:
      isFiatOrStablecoin && !shouldUseHigherPrecision
        ? DEFAULT_FIAT_CURRENCY_DECIMALS
        : fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  return shouldFormatCurrency
    ? formatCurrencySymbol(
        formatter.format(roundedAmount),
        currencySymbol,
        '',
        isFiatOrStablecoin,
      )
    : formatter.format(roundedAmount);
};

export const getTxUrl = (chain: Chain | null, txHash: string | undefined) => {
  if (!chain?.id || !chain?.blockExplorers?.default.url || !txHash) return '';
  // Use ChainInfo instead of Chain because we have selected specific explorers
  const chainInfo = getChain(chain?.id);
  return `${chainInfo.explorerUrl}/tx/${txHash}`;
};

export const normalizeBigInt = (amount: bigint, decimals: number) => {
  const divisor = 10 ** decimals;

  const normalizedAmount = Number(amount) / divisor;

  return normalizedAmount;
};

export const parseConfigString = (configString: string) => {
  const params = new URLSearchParams(configString);
  const tokens = params.get('tokens') ? params.get('tokens')?.split(',') : [];
  const chains = params.get('chains') ? params.get('chains')?.split(',') : [];
  const config = {
    tokens: tokens,
    chains: chains,
    currency: params.get('currency') ?? undefined,
    avatar: params.get('avatar') ?? undefined,
  };
  return config;
};
