import React, { PropsWithChildren } from 'react';
import { Container, Grid, GridProps } from '@radix-ui/themes';

interface Props {
  gap?: GridProps['gap'];
  className?: string;
  containerClassName?: string;
}

const AppContainer: React.FC<PropsWithChildren<Props>> = ({
  children,
  gap = '2',
  className,
  containerClassName,
}) => {
  return (
    <Container
      size="1"
      px={{ initial: '4', sm: '0' }}
      className={containerClassName}
    >
      <Grid gap={gap} mt="6" className={className}>
        {children}
      </Grid>
    </Container>
  );
};

export default AppContainer;
