'use client';

import { cn } from '@frontend/utils/tailwindUtil';
import { useMediaQuery } from '@frontend/utils/UseMediaQuery';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross1Icon } from '@radix-ui/react-icons';
import { Flex, IconButton, Text, VisuallyHidden } from '@radix-ui/themes';
import { FC, ReactNode } from 'react';
import logoDark from '@frontend/public/assets/images/logo.svg';
import Image from 'next/image';
import AppContainer from './ui/AppContainer';

export interface FullScreenDialogProps {
  children: ReactNode;
  title?: string | ReactNode;
  open: boolean;
  onOpenChange?: (open: boolean) => void;
  handleOnClose?: () => void;
  modal?: boolean;
  key?: string;
  showClose?: boolean;
  className?: string;
  fullScreenPage?: boolean;
  isCustomBg?: boolean;
  addDesktopPadding?: boolean;
  containerClassName?: string;
}

export const FullScreenDialogHeaderHeight = 10 * 10;

export const FullScreenDialog: FC<FullScreenDialogProps> = ({
  children,
  title,
  open,
  onOpenChange,
  handleOnClose,
  showClose = true,
  className,
  fullScreenPage = false,
  isCustomBg = false,
  addDesktopPadding = false,
  containerClassName,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const effectiveFullScreenPage = !isMobile && fullScreenPage;

  const handleCloseClick = () => {
    onOpenChange?.(false);
    handleOnClose?.();
  };

  const renderTitle = () => {
    if (typeof title === 'string') {
      return (
        <Text size="5" weight="bold">
          {title}
        </Text>
      );
    }
    return title;
  };

  const dialogContentClass = cn(
    !effectiveFullScreenPage && [
      'fixed bg-[var(--color-panel-solid)]',
      'inset-0 flex flex-col',
      'md:inset-auto md:left-1/2 md:top-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2',
      'md:w-full md:max-w-md md:max-h-[90vh] md:rounded-xl md:shadow-xl',
      addDesktopPadding && 'md:py-2 md:px-4 md:pb-5',
      'z-50',
    ],
    className,
  );

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} modal={false}>
      {open && !effectiveFullScreenPage && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur transition z-40" />
      )}

      <Dialog.Content className={dialogContentClass}>
        <VisuallyHidden>
          <Dialog.Title>
            <Image
              src={logoDark}
              alt="Yodl Logo"
              className={cn(
                'flex mx-auto justify-center items-center',
                isCustomBg && 'brightness-0 invert',
              )}
              width={70}
              priority
            />
          </Dialog.Title>
        </VisuallyHidden>
        <VisuallyHidden>
          <Dialog.Description />
        </VisuallyHidden>
        <VisuallyHidden>
          <Dialog.Close />
        </VisuallyHidden>
        <Flex
          justify="between"
          align="center"
          className={cn(!effectiveFullScreenPage ? 'p-4' : '!hidden')}
        >
          {showClose ? (
            <IconButton
              variant="ghost"
              radius="large"
              onClick={handleCloseClick}
              className="group"
            >
              <Cross1Icon
                className={cn(
                  isCustomBg &&
                    'text-white group-hover:text-white/80 transition',
                )}
              />
            </IconButton>
          ) : (
            <div className="w-8" />
          )}

          {title ? (
            renderTitle()
          ) : (
            <Image
              src={logoDark}
              alt="Yodl Logo"
              className={cn(
                'flex mx-auto justify-center items-center',
                isCustomBg && 'brightness-0 invert',
              )}
              width={70}
              priority
            />
          )}
          <div className="w-8" />
        </Flex>

        <AppContainer
          className={cn('sm:!mt-6 !mt-0', containerClassName)}
          containerClassName={containerClassName}
        >
          {children}
        </AppContainer>
      </Dialog.Content>
    </Dialog.Root>
  );
};
