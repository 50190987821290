import { formatUtils } from '@frontend/common/utils/format.utils';
import { formatUnits as viewFormatUnits } from 'viem';
import { dnformat } from '../utils';

export enum FiatCurrency {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  JPY = 'JPY',
  CNY = 'CNY',
  KRW = 'KRW',
  INR = 'INR',
  RUB = 'RUB',
  TRY = 'TRY',
  BRL = 'BRL',
  CAD = 'CAD',
  AUD = 'AUD',
  NZD = 'NZD',
  CHF = 'CHF',
  ILS = 'ILS',
  MXN = 'MXN',
}

export enum CryptoCurrency {
  ETH = 'ETH',
  WETH = 'WETH',
  USDT = 'USDT',
  USDC = 'USDC',
  EURe = 'EURe',
  DAI = 'DAI',
}

type FiatCurrencyDetails = {
  symbol: string;
  code: string;
  currency?: string;
};

type CryptoCurrencyDetails = {
  symbol: string;
  currency: string;
};

export const DEFAULT_FIAT_CURRENCY_DECIMALS = 2;
const FIAT_CURRENCY_DETAILS: Record<FiatCurrency, FiatCurrencyDetails> = {
  EUR: { symbol: '€', currency: 'Euro', code: 'EUR' },
  USD: { symbol: '$', currency: 'US Dollar', code: 'USD' },
  GBP: { symbol: '£', currency: 'Pound Sterling', code: 'GBP' },
  JPY: { symbol: '¥', currency: 'Japanese Yen', code: 'JPY' },
  CNY: { symbol: '₱', currency: 'Chinese Yuan', code: 'CNY' },
  KRW: { symbol: '₩', currency: 'South Korean Won', code: 'KRW' },
  INR: { symbol: '₹', currency: 'Indian Rupee', code: 'INR' },
  RUB: { symbol: '₽', currency: 'Russian Ruble', code: 'RUB' },
  TRY: { symbol: '₺', currency: 'Turkish Lira', code: 'TRY' },
  BRL: { symbol: 'R$ ', currency: 'Brazilian Real', code: 'BRL' },
  CAD: { symbol: 'C$ ', currency: 'Canadian Dollar', code: 'CAD' },
  AUD: { symbol: 'A$ ', currency: 'Australian Dollar', code: 'AUD' },
  NZD: { symbol: 'NZ$ ', currency: 'New Zealand Dollar', code: 'NZD' },
  CHF: { symbol: 'CHF', currency: 'Swiss Franc', code: 'CHF' },
  ILS: { symbol: '₪', currency: 'Israeli Shekel', code: 'ILS' },
  MXN: { symbol: 'Mex$', currency: 'Mexican Peso', code: 'MXN' },
};

const CRYPTO_CURRENCY_DETAILS: Record<CryptoCurrency, CryptoCurrencyDetails> = {
  ETH: { symbol: 'ETH', currency: 'Ethereum' },
  WETH: { symbol: 'WETH', currency: 'Wrapped Ethereum' },
  USDT: { symbol: 'USDT', currency: 'Tether' },
  USDC: { symbol: 'USDC', currency: 'USD Coin' },
  EURe: { symbol: 'EURe', currency: 'Euro Token' },
  DAI: { symbol: 'DAI', currency: 'Dai' },
};

/**
 * Formats a given amount in a specified fiat currency.
 *
 * @param {number} amount - The amount to format.
 * @param {FiatCurrency} currency - The fiat currency to format the amount in.
 * @param {boolean} [toggleSymbolPlacement=false] - Whether to place the symbol before (false) or after (true) the formatted amount.
 *
 * @example formatFiatCurrency(1000, FiatCurrency.USD, true) // 1,000.00$
 * @returns {string} The formatted currency string.
 */
export function formatFiatCurrency(
  amount: number,
  currency: FiatCurrency,
  toggleSymbolPlacement: boolean = false,
): string {
  const { symbol, code } = FIAT_CURRENCY_DETAILS[currency];

  const amountString = amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${toggleSymbolPlacement ? '' : symbol}${amountString}${toggleSymbolPlacement ? ` ${code}` : ''}`;
}

/**
 * Formats a given amount in a specified cryptocurrency.
 *
 * @param {number} amount - The amount to format.
 * @param {CryptoCurrency} currency - The cryptocurrency to format the amount in.
 *
 * @example formatCryptoAmount(1, CryptoCurrency.ETH) // 1 ETH
 *
 * @returns {string} The formatted cryptocurrency string.
 */
export function formatCryptoAmount(
  amount: number,
  currency: CryptoCurrency,
): string {
  const { symbol } = CRYPTO_CURRENCY_DETAILS[currency];
  return `${amount} ${symbol}`;
}

export function isCryptoCurrency(symbol: string): symbol is CryptoCurrency {
  return Object.values(CryptoCurrency).includes(symbol as CryptoCurrency);
}

/**
 * Checks if the given symbol is a valid fiat currency symbol.
 *
 * @param {string} symbol - The currency symbol to check.
 * @returns {boolean} `true` if the symbol is a valid fiat currency, otherwise `false`.
 *
 * @example
 * isFiatCurrency('USD'); // true
 * isFiatCurrency('ETH'); // false
 */
export function isFiatCurrency(symbol: string): symbol is FiatCurrency {
  return Object.values(FiatCurrency).includes(symbol as FiatCurrency);
}

/**
 * Formats a given value in a specified currency (fiat or crypto).
 *
 * @param {Object} params - The parameters for formatting the currency.
 * @param {number | string | bigint} [params.value] - The value to format.
 * @param {string} [params.symbol] - The currency symbol to format the value in.
 * @param {number} [params.decimals] - The number of decimal places for the currency. TODO: Create a hook that fetches the decimals for a given currency. Important for crypto currencies.
 *
 * @returns {string | undefined} The formatted currency string, or undefined if the value or symbol is invalid.
 *
 * @throws {Error} If the value, symbol or decimals are not provided.
 *
 * @example
 * formatAnyCurrency({ value: 1000, symbol: 'USD', decimals: 2 }); // $10.00
 * formatAnyCurrency({ value: 1, symbol: 'ETH', decimals: 18 }); // 1 ETH
 */
export const formatAnyCurrency = ({
  value,
  symbol,
  decimals,
}: {
  value: number | string | bigint;
  decimals: number;
  symbol: string;
}): string | undefined => {
  if (!value)
    throw new Error('Value must be provided to format currency value');
  if (decimals === undefined)
    throw new Error('Decimals must be provided to format currency value');

  if (isCryptoCurrency(symbol)) {
    return formatCryptoAmount(
      Number(dnformat({ value: BigInt(value), decimals })),
      symbol,
    );
  }

  if (isFiatCurrency(symbol)) {
    const formattedValue = dnformat({ value: BigInt(value), decimals });

    return formatFiatCurrency(Number(formattedValue), symbol);
  }

  // Most likely some unknown CryptoCurrency, lets format the value and append the symbol for now
  // Keep an eye on the vercel logs for any unknown symbols that users try to use
  const formattedValue = dnformat({ value: BigInt(value), decimals });
  return `${formattedValue} ${symbol}`;
};
