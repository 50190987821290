import { PREFERENCES_TEXT_KEY } from '@frontend/common';
import {
  SUPPORTED_CHAIN_IDS,
  YODL_ENS_DOMAIN,
} from '@frontend/common/constants';
import {
  chainsParamToSymbols,
  tokenParamToSymbols,
} from '@frontend/utils/parsers';
import { splitDomain } from '@justaname.id/react';
import { useDebounce } from '@justweb3/widget';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import assert from 'assert';
import { useMemo } from 'react';
import { isAddress } from 'viem';
import { justanameClient } from '../clients/justanameClient';

interface Props {
  addressOrEns?: string;
}

interface Preferences {
  chainIds: number[];
  tokenSymbols: string[];
  xAddress: string;
  xMessage: string;
  xSignature: string;
}

interface SignPreferencesProps {
  username?: string;
  isRegistered?: boolean;
}

const useSignedPreferences = ({ addressOrEns }: Props) => {
  const { debouncedValue } = useDebounce(addressOrEns, 500);

  // 1. Get address subnames
  const {
    data: addressSubnames,
    isFetching: isAddressSubnamesFetching,
    isLoading: isAddressSubnamesLoading,
  } = useQuery({
    queryKey: ['addressSubnames', debouncedValue],
    queryFn: () => {
      assert(debouncedValue, 'Address is required');

      console.log('addressSubnames');

      return justanameClient.subnames.getSubnamesByAddress({
        address: debouncedValue,
      });
    },
    enabled: !!debouncedValue && isAddress(debouncedValue),
  });

  // 1.1. Get ENS
  const {
    data: ensSubnames,
    isFetching: isEnsSubnamesFetching,
    isLoading: isEnsSubnamesLoading,
  } = useQuery({
    queryKey: ['ensSubnames', debouncedValue],
    queryFn: () => {
      assert(debouncedValue, 'ENS is required');

      return justanameClient.subnames.searchSubnames({
        name: debouncedValue,
        chainId: 1,
        data: false,
        isClaimed: true,
        skip: 0,
        take: 1,
        ensRegistered: true,
      });
    },
    enabled: !!debouncedValue && !isAddress(debouncedValue),
  });

  // 2. Find the subname that matches the ydomain
  // TODO: Use every subname to get the preferences
  const preferencesFromSubname = useMemo(() => {
    const yodlSubname = addressSubnames?.subnames?.find((subname) =>
      subname.ens.endsWith(YODL_ENS_DOMAIN),
    );

    const yodlSubnameFromEns = ensSubnames?.domains?.find((domain) => {
      if (typeof domain === 'string') {
        return undefined;
      }

      // Make sure it's the exact domain and yodl.eth
      if (domain.ens !== debouncedValue) return undefined;

      return domain;
    });

    return yodlSubname || yodlSubnameFromEns;
  }, [addressSubnames?.subnames, debouncedValue, ensSubnames?.domains]);

  // 3. Get the preferences from the subname record
  const parsePreferences = (preferences: string) => {
    const { chainIds = SUPPORTED_CHAIN_IDS, tokenSymbols } =
      JSON.parse(preferences);

    return {
      chainIds: chainsParamToSymbols(chainIds),
      tokenSymbols: tokenParamToSymbols(tokenSymbols),
    };
  };

  const preferencesFromSubnameRecord = useMemo(() => {
    if (!preferencesFromSubname || typeof preferencesFromSubname === 'string')
      return undefined;

    const { records } = preferencesFromSubname;

    const preferencesText = records.texts.find(
      (text) => text.key === PREFERENCES_TEXT_KEY,
    );

    return preferencesText
      ? parsePreferences(preferencesText.value)
      : undefined;
  }, [preferencesFromSubname]);

  const isLoading = useMemo(() => {
    return isAddressSubnamesLoading || isEnsSubnamesLoading;
  }, [isAddressSubnamesLoading, isEnsSubnamesLoading]);

  const isFetching = useMemo(() => {
    return isAddressSubnamesFetching || isEnsSubnamesFetching;
  }, [isAddressSubnamesFetching, isEnsSubnamesFetching]);

  return {
    preferences: preferencesFromSubnameRecord,
    isLoading,
    isFetching,
  };
};

const useSignPreferences = ({
  username,
  isRegistered,
}: SignPreferencesProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      tokenSymbols,
      chainIds,
      xAddress,
      xMessage,
      xSignature,
    }: Preferences) => {
      assert(username, 'ENS is required');

      const preferences: Record<string, any> = { tokenSymbols };
      if (chainIds.length > 0) preferences.chainIds = chainIds;

      const text = { [PREFERENCES_TEXT_KEY]: JSON.stringify(preferences) };
      const [ensUsername, ensDomain] = splitDomain(username);

      return isRegistered
        ? await justanameClient.subnames.updateSubname(
            {
              text,
              username: ensUsername,
              ensDomain: YODL_ENS_DOMAIN,
            },
            {
              xAddress,
              xMessage,
              xSignature,
            },
          )
        : await justanameClient.subnames.addSubname(
            {
              text,
              username,
              ensDomain: YODL_ENS_DOMAIN,
            },
            {
              xAddress,
              xMessage,
              xSignature,
              xApiKey: process.env.NEXT_PUBLIC_MAINNET_API_KEY,
            },
          );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === 'addressSubnames' ||
            query.queryKey[0] === 'ensSubnames'
          );
        },
      });
    },
  });
};

export { useSignedPreferences, useSignPreferences };
