import { YappConfig } from '@frontend/actions/resolveYapp';
import { TokenBalance } from '@frontend/app/(routes)/go/send/balances/route';
import { TokenInResponse } from '@frontend/client/hooks/tokenIn';
import { Quote, USDStableSymbol } from '@frontend/common';
import { YodlPaymentPriceFeeds } from '@frontend/shared/pricefeeds';
import { IdResolution, SavedLink, TokenValue } from '@frontend/types';
import { TokenInfo } from '@yodlpay/tokenlists';
import { Address, WalletCapabilities } from 'viem';
import { create } from 'zustand';
import { PaymentPreferences } from '../hooks/preferences';
import { Quote as AcrossQuote } from '@across-protocol/app-sdk';

export const validThemeAccents = [
  'gray',
  'gold',
  'bronze',
  'brown',
  'yellow',
  'amber',
  'orange',
  'tomato',
  'red',
  'ruby',
  'crimson',
  'pink',
  'plum',
  'purple',
  'violet',
  'iris',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'jade',
  'green',
  'grass',
  'lime',
  'mint',
  'sky',
];
export const validThemeGrays = [
  'auto',
  'gray',
  'mauve',
  'slate',
  'sage',
  'olive',
  'sand',
];
export type YodlTheme = {
  accent:
    | 'gray'
    | 'gold'
    | 'bronze'
    | 'brown'
    | 'yellow'
    | 'amber'
    | 'orange'
    | 'tomato'
    | 'red'
    | 'ruby'
    | 'crimson'
    | 'pink'
    | 'plum'
    | 'purple'
    | 'violet'
    | 'iris'
    | 'indigo'
    | 'blue'
    | 'cyan'
    | 'teal'
    | 'jade'
    | 'green'
    | 'grass'
    | 'lime'
    | 'mint'
    | 'sky';
  gray: 'auto' | 'gray' | 'mauve' | 'slate' | 'sage' | 'olive' | 'sand';
};

export type TokenInVariant = 'transfer' | 'stableswap' | 'tokenswap' | 'bridge';

export type TokenSelection = {
  token: TokenInfo;
  variant: TokenInVariant;
  tokenOuts?: TokenInfo[];
  convenienceFee?: bigint;
};

export const EMPTY_TOKEN_IN = {
  amount: {
    value: 0n,
    decimals: 1,
  },
  error: null,
  isLoading: false,
} as TokenInResponse;

const initialState = {
  handle: undefined,
  yd: undefined, // yodl id to track meta data
  walletCapabilities: undefined,
  theme: {
    accent: 'indigo',
    gray: 'auto',
  } as YodlTheme,
  // URL params
  playwright: undefined,
  // Receiver
  receiver: undefined,
  idResolution: undefined,
  receiverBalances: [],

  preferencesFromYodl: undefined,
  preferencesFromENS: undefined,

  // Wallet Connection
  sender: undefined,
  senderInfo: undefined,
  senderBalances: [],
  // TokenOut Dialog
  invoiceAmount: undefined, // what the user enters
  invoiceCurrency: 'USD', // what currency the user selects

  pricefeeds: undefined, // pricefeed (recently looked up)
  exchangeRate: undefined,
  pricefeed0: undefined, // pricefeed (recently looked up)

  tokenOut: undefined,
  tokenOutAmount: undefined, // the tokenOutAmount calculated from invoiceAmount x priceFeeds
  // TokenIn Dialog
  tokenIn: undefined,
  tokenSelection: undefined,
  tokenInAmount: EMPTY_TOKEN_IN,

  // User Selection:
  selectedPaymentPreference: undefined,
  paymentNotification: undefined,

  swapQuote: undefined,
  swapQuotes: [],
  // Other
  savedLinks: [],
  urlConfig: {} as PaymentConfig,
  yapp: undefined,
  yappTxAccessKey: undefined,
  yappConfigs: [],
  recentTransactionHash: undefined, // Used to send the transaction hash to the receiver to generate a link

  acrossQuote: undefined,
};

export type PaymentConfig = {
  amount?: number;
  currency?: string;
  chainIds?: number[];
  tokenSymbols?: USDStableSymbol[];
  memo?: string;
  theme?: string;
  urlTokenIn?: TokenInfo | undefined;
};

type YodlPaymentStoreType = {
  yd: string | undefined; // bigint
  playwright: Address | undefined;
  walletCapabilities: WalletCapabilities | undefined;
  recentTransactionHash?: string;
  theme: YodlTheme;
  handle: string | undefined;
  receiver: Address | undefined;
  idResolution: IdResolution | undefined;
  sender: Address | undefined;
  senderInfo: any;
  // useful to remember "previous" token when switching chains

  preferencesFromYodl: PaymentConfig | undefined;
  preferencesFromENS: PaymentConfig | undefined;

  // invoiceAmount is entered manually by the user. unlikely to be bigint-material
  invoiceAmount: number | undefined;
  invoiceCurrency: string;
  pricefeeds: YodlPaymentPriceFeeds[] | undefined;

  exchangeRate: bigint | undefined;
  // pricefeed0 converts the users selected invoiceCurrency into USD
  pricefeed0: YodlPaymentPriceFeeds | undefined;

  tokenInAmount: TokenInResponse;
  tokenIn: TokenSelection | undefined;
  tokenSelection: TokenSelection | undefined;
  tokenOut: TokenInfo | undefined;
  tokenOutAmount: TokenValue | undefined;

  savedLinks: SavedLink[];

  swapQuote: Quote | undefined;
  swapQuotes: Quote[];

  senderBalances: TokenBalance[];
  receiverBalances: TokenBalance[];

  urlConfig: PaymentConfig;
  yapp: any;
  yappTxAccessKey: string | undefined;
  yappConfigs: YappConfig[];

  selectedPaymentPreference: PaymentPreferences | undefined;

  // This is used to bridge tokens
  acrossQuote: AcrossQuote | undefined;
  setAcrossQuote: (crossQuote: AcrossQuote | undefined) => void;

  paymentNotification: any;

  setYd: (yd: string) => void;
  setYapp: (yapp: any) => void;
  setYappConfigs: (yappConfigs: any) => void;
  setYappTxAccessKey: (yappTxAccessKey: string | undefined) => void;
  setWalletCapabilities: (
    walletCapabilities: WalletCapabilities | undefined,
  ) => void;
  setTheme: (theme: YodlTheme) => void;
  setHandle: (handle: string | undefined) => void;
  setPlaywright: (playwright?: Address) => void;
  setUrlConfig: (urlConfig: PaymentConfig) => void;
  setReceiver: (address?: Address) => void;
  setIdResolution: (idResolution?: IdResolution) => void;
  setSender: (address: Address) => void;
  setSenderBalances: (balances: TokenBalance[]) => void;
  setReceiverBalances: (balances: TokenBalance[]) => void;
  setTokenOut: (tokenOut: TokenInfo | undefined) => void;
  setTokenIn: (tokenIn: TokenSelection | undefined) => void;
  setTokenSelection: (tokenSelection: TokenSelection | undefined) => void;
  setInvoiceAmount: (invoiceAmount: number | undefined) => void;

  setInvoiceCurrency: (currency: string) => void;
  setPricefeeds: (pricefeeds: YodlPaymentPriceFeeds[] | undefined) => void;
  setExchangeRate: (exchangeRate: bigint | undefined) => void;

  setTokenInAmount: (tokenInAmount: TokenInResponse) => void;
  setTokenOutAmount: (tokenOutAmount: TokenValue | undefined) => void;

  setSwapQuote: (swapQuote: Quote | undefined) => void;
  setSwapQuotes: (swapQuotes: Quote[]) => void;
  addSwapQuote: (swapQuote: Quote) => void;

  setSavedLinks: (savedlinks: SavedLink[]) => void;

  setRecentTransactionHash: (hash: string | undefined) => void;

  setPreferencesFromYodl: (preferences: PaymentConfig) => void;
  setPreferencesFromENS: (preferences: PaymentConfig) => void;

  setSelectedPaymentPreference: (preferences: PaymentPreferences) => void;
  setPaymentNotification: (notification: any) => void;

  reset: () => void;
};

// priceFeeds <- [invoiceCurrency, tokenOut]
// tokenOutAmount <- [invoiceAmount, priceFeeds, tokenOut]

export const useYodlStore = create<YodlPaymentStoreType>((set, get) => ({
  ...initialState,
  setYd: (yd) => set({ yd }),
  setYapp: (yapp) => set({ yapp }),
  setYappConfigs: (yappConfigs) => set({ yappConfigs }),
  setYappTxAccessKey: (yappTxAccessKey) => set({ yappTxAccessKey }),
  setWalletCapabilities: (walletCapabilities) => set({ walletCapabilities }),
  setTheme: (theme: YodlTheme) => set({ theme }),
  setUrlConfig: (urlConfig) => set({ urlConfig }),
  setHandle: (handle) => set({ handle }),
  setPlaywright: (playwrightAddress) => set({ playwright: playwrightAddress }),
  setReceiver: (receiver) => set({ receiver }),
  setIdResolution: (idResolution) => set({ idResolution }),
  setSender: (sender) => set({ sender }),

  setSenderBalances: (senderBalances) => set({ senderBalances }),
  setReceiverBalances: (receiverBalances) => set({ receiverBalances }),

  setTokenSelection: (selectedTokenIn) =>
    set({ tokenSelection: selectedTokenIn }),

  setTokenOut: (tokenOut) => set({ tokenOut }),
  setTokenIn: (tokenIn) => set({ tokenIn }),

  setTokenInAmount: (tokenInAmount) => set({ tokenInAmount }),
  setTokenOutAmount: (tokenOutAmount) => set({ tokenOutAmount }),
  // Save invoice amount as integer
  setInvoiceAmount: (invoiceAmount) => set({ invoiceAmount }),

  setInvoiceCurrency: (invoiceCurrency) => set({ invoiceCurrency }),
  setPricefeeds: (pricefeeds: YodlPaymentPriceFeeds[] | undefined) =>
    set({ pricefeeds }),
  setExchangeRate: (exchangeRate: bigint | undefined) => set({ exchangeRate }),

  setSwapQuote: (swapQuote: Quote | undefined) => set({ swapQuote }),
  setSwapQuotes: (swapQuotes: Quote[]) => set({ swapQuotes }),
  addSwapQuote: (swapQuote: Quote) =>
    set({ swapQuotes: [...get().swapQuotes, swapQuote] }),

  setPreferencesFromYodl: (preferencesFromYodl: PaymentConfig | undefined) =>
    set({ preferencesFromYodl }),
  setPreferencesFromENS: (preferencesFromENS: PaymentConfig | undefined) =>
    set({ preferencesFromENS }),

  setSavedLinks: (savedLinks: SavedLink[]) => set({ savedLinks }),
  setRecentTransactionHash: (recentTransactionHash) =>
    set({ recentTransactionHash }),

  setSelectedPaymentPreference: (
    selectedPaymentPreference: PaymentPreferences,
  ) => set({ selectedPaymentPreference }),

  setPaymentNotification: (paymentNotification: any) =>
    set({ paymentNotification }),

  setAcrossQuote: (acrossQuote: AcrossQuote | undefined) =>
    set({ acrossQuote }),

  reset: () => set(initialState),
}));

export const paymentStore = useYodlStore;
